import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { Link } from 'react-router-dom';
import { Header } from '../../components/UserHeader';
import { FieldItem } from 'components';
import Skeleton from '@mui/material/Skeleton';
import { unwrapResult } from '@reduxjs/toolkit'
import {
  BigEditIcon,
  NewsIcon,
  ProfileIcon,
  BellIcon,
  CommunityIcon,
} from 'assets';
import AccordionItems from 'components/AccordionItems';
import Notification from 'components/Notification';
import {
  getAssessments,
  getAssessmentResult,
  inviteAssessment,
  getTechnicalAssessments,
  getTechnicalAssessmentResult,
} from 'api/services/assessment';
import { getProfile } from 'api/services/user';
import { resetStatus } from 'redux/user/assessmentReducer';
import { Button } from 'components';
import { NewsItems, AcademyItems, COMMUNITY_URL, DISCORD_URL } from 'constant';
import { FooterNew } from 'components/FooterNew';
import AgreementModal from './agreementModal';
import AppealModal from './appealModal';
import { MembersService } from 'api/services/members';

const agreementFlag = process.env.REACT_APP_CMA_ENABLED === 'true';

function Dashboard() {
  const dispatch = useAppDispatch();
  const [isFetchResultLoading, setIsFetchResultLoading] = useState(true);
  const [isAgreementOpen, setAgreementOpen] = useState(false);
  const [isAppealOpen, setAppealOpen] = useState(false);
  const [testType, setTestType] = useState('')
  const assessments = useAppSelector(
    (state) => state.assessment.assessments || []
  );
  const technicalAssessments = useAppSelector(
    (state) => (state.assessment.technicalAssessments || []).filter(i => !i.deprecatedAt)
  );

  const profile = useAppSelector((state) => state.user.profile);
  const [notification, setNotification] = useState({
    message: '',
    severity: '',
    open: false
  });
  const testResult = useAppSelector((state) => state.assessment.testResult);
  const testTechnicalResult = useAppSelector((state) => state.assessment.testTechnicalResult);
  const inviteAssessments = useAppSelector((state) => state.assessment.inviteAssessment);
  const isTechnicalAssessmentResultLoading = useAppSelector((state) => state.assessment.isTechnicalAssessmentResultLoading);
  const isGetAssessmentResultLoading = useAppSelector((state) => state.assessment.isGetAssessmentResultLoading);
  const isInviteAssessmentLoading = useAppSelector((state) => state.assessment.isInviteAssessmentLoading);
  const isAssessmentLoading = useAppSelector((state) => state.assessment.isAssessmentLoading);
  const isTechnicalAssessmentLoading = useAppSelector((state) => state.assessment.isTechnicalAssessmentLoading);

  const [expanded, setExpanded] = React.useState('0');

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (!isAssessmentLoading && !testResult) {
      dispatch(getAssessments());
    }
    if (!isTechnicalAssessmentLoading && !testTechnicalResult) {
      dispatch(getTechnicalAssessments());
    }
  }, []);

  useEffect(() => {
    if ((isTechnicalAssessmentResultLoading || isGetAssessmentResultLoading) && isFetchResultLoading) {
      setIsFetchResultLoading(false);
    }
  }, [isGetAssessmentResultLoading, isGetAssessmentResultLoading]);

  useEffect(() => {
    if (profile?.id) {
      if (!isGetAssessmentResultLoading) {
        dispatch(getAssessmentResult(profile.id));
      }
      if (!isTechnicalAssessmentResultLoading) {
        dispatch(getTechnicalAssessmentResult(profile.id));
      }
    }
  }, [profile])

  useEffect(() => {
    if (inviteAssessments?.signInUrl) {
      window.open(inviteAssessments?.signInUrl, '_blank', 'noopener,noreferrer');
      dispatch(resetStatus())
    }
  }, [inviteAssessments])

  const onClose = () => {
    setNotification({
        message: '',
        severity: '',
        open: false
    })
  }

  const openDiscord = () => {
    window.open(DISCORD_URL, '_blank');
  }

  const onOpenAgreement = () => {
    setAgreementOpen(true);
  }

  const onCloseAgreement = () => {
    setAgreementOpen(false);
    dispatch(getProfile());
  }

  const onCloseAppeal = () => {
    setAppealOpen(false);
  }

  const onSubmit = async ({ assessmentId, link }) => {
    if (assessmentId && profile) {
      try {
        const res: any = dispatch(inviteAssessment({
          email: profile?.personalEmail,
          giveName: profile?.firstName,
          surName: profile?.lastName,
          assessmentId: assessmentId
        }))
        .unwrap();
        if (res) {
          window.open(res?.signInUrl, '_blank', 'noopener,noreferrer');
        }
      }catch {(error => {
        setNotification({
          severity: 'error',
          open: true,
          message: error?.message || 'Something went wrong'
        });
      })}
    } else if (link) {
      if (profile?.id) {
        try {
          const res = await dispatch(getTechnicalAssessmentResult(profile.id)).unwrap()
          if (res && res?.status) {
            setNotification({
              severity: 'error',
              open: true,
              message: "You have already taken the test. Please check your email for the result."
            });
          } else {
            const name = encodeURIComponent(profile?.firstName + ' ' + profile?.lastName);
            window.open(`${link}&email=${profile?.personalEmail}&name=${name}`, '_blank', 'noopener,noreferrer');
          }
        } catch (error) {
          const name = encodeURIComponent(profile?.firstName + ' ' + profile?.lastName);
          window.open(`${link}&email=${profile?.personalEmail}&name=${name}`, '_blank', 'noopener,noreferrer');
        }

      }
     
    }
  }

  const onOpenAppeal = (id) => {
    setAppealOpen(true)
    setTestType(id === 1 ? 'technical' : 'english')
  }

  const onAppeal = async (html: string) => {
    if (testType) {
      const subject = `${profile?.firstName} ${profile?.lastName} is requesting to retake the ${testType} test`;
      try {
        await MembersService.UserTestAppeal({html: html + `<a href='mailto:${profile?.personalEmail}'>Reply To Candidate</a>\n`, subject});
        setNotification({
          severity: 'success',
          open: true,
          message: "You have successfully submitted your appeal. We will get back to you soon."
        });
      } 
      catch (error: any) {
        setNotification({
          severity: 'error',
          open: true,
          message: error?.message || 'Something went wrong'
        });
      } finally {
        onCloseAppeal()
      }
    }
  }

  const AcademyLoader = () => {
    return (
      <div className="flex flex-col w-[100%]">
        <div className='flex flex-row gap-x-4 items-center w-[100%]'>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '60%' }} />
        </div>
        <div className='flex flex-col w-[100%] ml-10'>
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
        </div>
      </div>
    )
  }

  return (
    <div className="h-screen relative w-full overflow-hidden">
      <Header />
      <div className='dashboard-screen overflow-auto bg-[#F7F7F7]' style={{ height: 'calc(100% - 56px)'}}>
        <div className="flex flex-wrap-reverse justify-center p-4 md:pt-12 xl:space-x-8 text-center w-[100%]">
          <div className="max-w-[100%] w-[100%] xl:max-w-[375px] box-border xl:mx-4 flex flex-col items-center">
            <FieldItem
              title={
                <div>
                  Elastic Profile
                </div>
              }
              icon={<ProfileIcon />}
            >
              <Link to="/new-applicant" >
                <div className="p-4 flex flex-row justify-between">
                  <div className="font-poppins-bold text-[14px] text-[#363D49] cursor-pointer ml-2">
                    Edit Elastic Profile
                  </div>
                  <span className="cursor-pointer ">
                    <BigEditIcon />
                  </span>
                </div>
              </Link>
            </FieldItem>
            <FieldItem
              title={
                <div>
                  Know Elastic Team
                </div>
              }
              icon={<NewsIcon />}
            >
              <div className="p-2">
                {NewsItems.map((item, index) => {
                  return (
                    <div
                      className="p-2 flex flex-row justify-start items-center"
                      key={index}
                    >
                      <a className="font-poppins-bold text-[14px] text-[#3168F4] cursor-pointer ml-2" href={item.link} target="_blank">
                        {item.label}
                      </a>
                    </div>
                  );
                })}
              </div>
            </FieldItem>
            <FieldItem
              title={'Get involved!'}
              icon={<CommunityIcon />}
            >
              <div className="p-2 flex flex-col">
                <div className="font-poppins-regular text-[14px] text-left text-[#555E6D] cursor-pointer ml-2 mt-2">
                  Complete your Technical and English language test to get access to our community portals below
                </div>
                <div className="py-4 flex flex-col sm:flex-row gap-x-2 space-y-4 sm:space-y-0 justify-center">
                  <Button
                    text="Join Discord"
                    className="px-5 !max-h-[32px] !min-h-[32px] font-poppins-regular uppercase text-[12px]"
                    type="submit"
                    style={{
                      background: !profile?.academyUserId ? '#E6E6E6' : '#144FE7',
                      color: !profile?.academyUserId ? '#808080' : 'white'
                    }}
                    disabled={!profile?.academyUserId}
                    onClick={openDiscord}
                  />
                  <Button
                    text="Start a conversation"
                    className="!px-5 !max-h-[32px] !min-h-[32px] font-poppins-regular uppercase text-[12px]"
                    style={{
                      background: !profile?.academyUserId ? '#E6E6E6' : '#144FE7',
                      color: !profile?.academyUserId ? '#808080' : 'white',
                    }}
                    type="submit"
                    disabled={!profile?.academyUserId}
                    onClick={() => window.open(COMMUNITY_URL, '_blank')}
                  />
                </div>
                <div className="font-poppins-regular text-[11px] text-[#707A89] cursor-pointer ml-2">
                  *To join this discussion, become an <a className='underline underline-offset-2'>Elastic Team Academy</a> member!
                </div>
              </div>
            </FieldItem>
          </div>
          <FieldItem
            title={
              <div>
                Notifications
              </div>
            }
            containerClass={'max-w-[774px] w-[100%] xl:max-w-[774px]'}
            icon={<BellIcon />}
          >
              {(isTechnicalAssessmentResultLoading || isGetAssessmentResultLoading || isFetchResultLoading) ? (
                <div className="flex justify-start items-center h-[100%] flex-col p-8 gap-y-4">
                  {Array(4).fill('').map((item, index) => (<AcademyLoader key={index} />))}
                  </div>
                 ) : (
                <div className='m-1 md:m-2'>
                  {(profile?.academyUserId ? (agreementFlag ? AcademyItems : [AcademyItems[0], AcademyItems[1], AcademyItems[2]])
                  : (profile?.isEngineer ? (agreementFlag ? [AcademyItems[1], AcademyItems[2], AcademyItems[3]] : [AcademyItems[1], AcademyItems[2]])
                  : (agreementFlag ? [AcademyItems[2], AcademyItems[3]] : [AcademyItems[2]]) )).map((item, index) => {
                    const hasCheckedAgreement = Object.hasOwn(profile || {}, 'acceptedCommunityMemberAgreement') && profile?.acceptedCommunityMemberAgreement !== null && item?.id === 3;
                    if (hasCheckedAgreement && agreementFlag) {
                      return null;
                    }
                    return (
                      <AccordionItems
                        item={item}
                        englishResult={testResult}
                        technicalResult={testTechnicalResult}
                        isLoading={isInviteAssessmentLoading}
                        key={index}
                        onSubmit={onSubmit}
                        onAppeal={onOpenAppeal}
                        openDiscord={openDiscord}
                        assessments={assessments}
                        technicalAssessments={technicalAssessments}
                        hasPassed={profile?.academyUserId}
                        profile={profile}
                        expanded={expanded}
                        handleChange={handleChange}
                        onOpenAgreement={onOpenAgreement}
                      />
                    )}
                  )}
                </div>
              )}
          </FieldItem>
          <AgreementModal 
            isOpen={isAgreementOpen}
            onClose={onCloseAgreement}
          />
          <AppealModal 
            isOpen={isAppealOpen}
            onClose={onCloseAppeal}
            onAppeal={onAppeal}
          />
        </div>
        <Notification {...notification} onClose={onClose}/>
        <FooterNew />
      </div>
    </div>
  );
}

export default Dashboard;
